.collapsible-header {
    display: flex;
    justify-content: space-between
}

.collapsible-header p {
    margin: 0
}

.collapsible-description {
    display: flex;
    justify-content: center;
    align-items: center
}