.green-button {
  background-color: #19c368;
}
.green-button:hover {
  background-color: #19c368;
  opacity: 0.9;
}

.blue-button {
  background-color: #076f9f;
}
.blue-button:hover {
  background-color: #076f9f;
  opacity: 0.9;
}

#create-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.submit-signup-company {
  margin-top: 30px
}

.submit-signup-company-container {
  display: flex;
  justify-content: flex-end
}

.bordered-center {
  display: flex;
  justify-content: center
}

.button-default {
  padding: 15px 30px;
  line-height: 1;
  border-radius: 50px;
  box-shadow: none;
  outline: none;
  border: none;
  text-transform: uppercase;
  font-family: PublicaSans-Bold, sans-serif;
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
}

.button-blue {
  background: #3dc6ef;
  color: white;
  transition: all 0.3s;
  border: 1px solid #3dc6ef;
  min-width: 200px
}

.button-blue:hover {
  background: #076f9f;
  transition: all 0.3s;
  border: 1px solid #076f9f
}

.button-dark-blue {
  background: #076f9f;
  color: white;
  transition: all 0.3s;
  border: 1px solid #076f9f;
  min-width: 200px
}

.button-outlined {
  background: none;
  color: white;
  border: 2px solid white;
}

.button-outlined:hover {
  background: white;
  color: rgb(0, 0, 0);
  transition: all 0.3s;
}
