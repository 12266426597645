footer p,
footer a {
  margin: 0px;
  color: #999999;
  font-size: 14px;
  line-height: 20px
}

.footer-container {
  display: flex;
  padding: 0px 80px;
  justify-content: space-between
}

.footer-content {
  padding: 40px 0px
}

.partners-container {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  margin-top: 10px
}

.footer-brand {
  margin-bottom: 25px
}

.company-details p:not(:last-of-type) {
  margin-bottom: 5px
}

.company-details {
  margin-bottom: 25px
}

.footer-partners img {
  height: 60px;
  width: auto
}

.footer-partners img:not(:last-of-type) {
  margin-right: 10px
}

.footer-title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 15px;
  cursor: pointer;
  display: block
}

.footer-title a {
  color: #999; 
}

.footer-partners:last-of-type {
  margin-top: 50px
}

.footer-about {
  border-top: 1px solid rgba(153,153,153, 0.3);
  padding: 40px 80px
}

.footer-about p span {
  font-weight: 600
}

.footer-about p:not(:last-of-type) {
  margin-bottom: 10px
}

.footer-link {
  font-size: 14px;
  line-height: 19px;
  color: #999999;
  display: block;
  transition: all 0.3s
}

.footer-links {
  margin-top: 10px
}

.footer-link:hover {
  color: #039be5;
  transition: all 0.3s
}

.footer-link:not(:last-of-type) {
  margin-bottom: 10px
}

.social-networks a:not(:last-of-type) {
  margin-right: 10px
}

.social-networks a { 
  color: #076f9f;
}

@media (max-width: 768px) {
  .footer-container {
    padding: 0px 40px
  }

  .footer-container .footer-content:not(:last-of-type) {
    padding-right: 10px
  }

  .footer-about {
    padding: 40px
  }
}


@media (max-width: 578px) {
  .footer-container {
    padding: 0px 20px;
    flex-direction: column
  }

  .footer-container .footer-content:not(:last-of-type) {
    padding-right: 10px
  }

  .footer-about {
    padding: 40px 20px
  }
}