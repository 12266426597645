input.valid:not([type]),
input.valid:not([type]):focus,
input.valid[type='text']:not(.browser-default),
input.valid[type='text']:not(.browser-default):focus,
input.valid[type='password']:not(.browser-default),
input.valid[type='password']:not(.browser-default):focus,
input.valid[type='email']:not(.browser-default),
input.valid[type='email']:not(.browser-default):focus,
input.valid[type='url']:not(.browser-default),
input.valid[type='url']:not(.browser-default):focus,
input.valid[type='time']:not(.browser-default),
input.valid[type='time']:not(.browser-default):focus,
input.valid[type='date']:not(.browser-default),
input.valid[type='date']:not(.browser-default):focus,
input.valid[type='datetime']:not(.browser-default),
input.valid[type='datetime']:not(.browser-default):focus,
input.valid[type='datetime-local']:not(.browser-default),
input.valid[type='datetime-local']:not(.browser-default):focus,
input.valid[type='tel']:not(.browser-default),
input.valid[type='tel']:not(.browser-default):focus,
input.valid[type='number']:not(.browser-default),
input.valid[type='number']:not(.browser-default):focus,
input.valid[type='search']:not(.browser-default),
input.valid[type='search']:not(.browser-default):focus,
textarea.materialize-textarea.valid,
textarea.materialize-textarea.valid:focus,
.select-wrapper.valid > input.select-dropdown {
  border-bottom: 0px solid #076f9f !important;
  box-shadow: 0 0px 0 0 #076f9f !important;
}

input:not([type]):focus.valid ~ label,
input[type='text']:not(.browser-default):focus.valid ~ label,
input[type='password']:not(.browser-default):focus.valid ~ label,
input[type='email']:not(.browser-default):focus.valid ~ label,
input[type='url']:not(.browser-default):focus.valid ~ label,
input[type='time']:not(.browser-default):focus.valid ~ label,
input[type='date']:not(.browser-default):focus.valid ~ label,
input[type='datetime']:not(.browser-default):focus.valid ~ label,
input[type='datetime-local']:not(.browser-default):focus.valid ~ label,
input[type='tel']:not(.browser-default):focus.valid ~ label,
input[type='number']:not(.browser-default):focus.valid ~ label,
input[type='search']:not(.browser-default):focus.valid ~ label,
textarea.materialize-textarea:focus.valid ~ label {
  color: #076f9f !important;
}

.btn:hover,
.btn-large:hover,
.btn-small:hover {
  background-color: #076f9f !important;
}

button:focus {
  background-color: transparent !important;
}

input:not([type]):focus:not([readonly]),
input[type='text']:not(.browser-default):focus:not([readonly]),
input[type='password']:not(.browser-default):focus:not([readonly]),
input[type='email']:not(.browser-default):focus:not([readonly]),
input[type='url']:not(.browser-default):focus:not([readonly]),
input[type='time']:not(.browser-default):focus:not([readonly]),
input[type='date']:not(.browser-default):focus:not([readonly]),
input[type='datetime']:not(.browser-default):focus:not([readonly]),
input[type='datetime-local']:not(.browser-default):focus:not([readonly]),
input[type='tel']:not(.browser-default):focus:not([readonly]),
input[type='number']:not(.browser-default):focus:not([readonly]),
input[type='search']:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 0px solid #076f9f !important;
  box-shadow: 0 0px 0 0 #076f9f !important;
}

input:not([type]):focus:not([readonly]) + label,
input[type='text']:not(.browser-default):focus:not([readonly]) + label,
input[type='password']:not(.browser-default):focus:not([readonly]) + label,
input[type='email']:not(.browser-default):focus:not([readonly]) + label,
input[type='url']:not(.browser-default):focus:not([readonly]) + label,
input[type='time']:not(.browser-default):focus:not([readonly]) + label,
input[type='date']:not(.browser-default):focus:not([readonly]) + label,
input[type='datetime']:not(.browser-default):focus:not([readonly]) + label,
input[type='datetime-local']:not(.browser-default):focus:not([readonly]) + label,
input[type='tel']:not(.browser-default):focus:not([readonly]) + label,
input[type='number']:not(.browser-default):focus:not([readonly]) + label,
input[type='search']:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label {
  color: #076f9f !important;
}
