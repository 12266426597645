.short-desc p {
  margin-right: 15px;
  margin-left: 15px;
  text-align: justify;
  color: black;
}
.cube-title {
  color: black;
  margin-top: auto;
  margin-bottom: auto;
}
.info-area {
  margin: 15px 15px 5px 15px;
}
.short-desc * {
  color: black !important;
}
.short-desc {
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.show-details {
  color: #076f9f;
  font-size: 12pt;
  padding-top: 10px;
  padding-bottom: 15px;
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  background: white;
  justify-content: center;
  cursor: pointer;
}
body {
  font-family: sans-serif;
}
.scene {
  height: 585px;
  border: 1px solid #ddd;
  margin-top: 40px;
  margin-bottom: 40px;
  perspective: 1000px;

  border: 0px;
}
.cube {
  margin-left: 2px;
  margin-right: 2px;
  width: 300px;
  height: inherit;
  position: relative;

  transform: translateZ(-150px);
  transform-style: preserve-3d;
  transition: transform 1s;
}

.cube__face {
  padding: 2px;
  position: absolute;
  width: 300px;
  height: inherit;
  border: 1px solid #ddd;

  border-radius: 5px;
  color: white;
  text-align: center;
  -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.14), 0 6px 2px -4px rgba(0, 0, 0, 0.12),
    0 2px 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.14), 0 6px 2px -4px rgba(0, 0, 0, 0.12), 0 2px 10px 0 rgba(0, 0, 0, 0.2);
}
.status-campaign-img {
  top: 10%;
  left: 0;
}

.cube__face--front {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
}
.cube__face--right {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  overflow: hidden;
}
.align-to-start {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
}

.cube__face--front {
  transform: rotateY(0deg) translateZ(150px);
}
.cube__face--right {
  transform: rotateY(90deg) translateZ(150px);
}

@media screen and (min-width: 405px) and (max-width: 465px) {
  .scene {
    height: 600px;
  }
}
@media screen and (min-width: 466px) and (max-width: 600px) {
  .scene {
    height: 700px;
  }
}
@media screen and (min-width: 845px) and (max-width: 1000px) {
  .scene {
    height: 620px;
  }
}
@media screen and (min-width: 1600px) {
  .scene {
    height: 700px;
  }
}

@media screen and (min-width: 1024px) {
  #campaigns-page .container {
    width: 75% !important;
    max-width: auto !important;
  }

  #campaigns-page .container .row .col {
    padding: 0px 25px !important;
  }
}

@media screen and (min-width: 768px) {
  #campaigns-page .container {
    width: auto;
    max-width: auto;
    padding: 0px 20px;
  }

  #campaigns-page .container .row .col {
    padding: 0px 20px;
  }
}

@media screen and (max-width: 600px) {
  #campaigns-page .container {
    width: auto;
    max-width: auto;
    padding: 40px;
  }

  #campaigns-page .container .row .col {
    padding: 0px;
  }
}
