.laptop-container {
  background-image: linear-gradient(to right, #00a1c9, #0071a3);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  padding: 100px 50px;
}
  
.laptop-text {
    color: white;
    text-align: left;
  }

.laptop-text-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  

  .laptop-title {
    font-size: 2.2rem;
  }
  
  .laptop-body {
    font-size: 1.2rem;
    margin-top: 0;
    line-height: 1.5rem;
    max-width: 300px;
  }
  
  .laptop-image-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .laptop-image-wrapper img {
      width: 100%;
  }
  
  @media (max-width: 768px) {
    .laptop-text {
      flex: 1;
      margin-bottom: 50px;
    }
  
    .laptop-container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: auto;
      padding: 50px 50px;
    }
  
    .laptop-image-wrapper {
      flex: 1;
    }
  
    .laptop-title {
      font-size: 1.5rem;
    }
  
    .laptop-body {
      font-size: 0.9rem;
    }
  }
  