.whats-app-image {
  min-height: 250px;
  background-image: url("/assets/img/home/whatsapp-background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.whats-app-content {
  background-color: rgba(0, 0, 0, 0.5);
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.whats-app-content img {
  width: 140px;
}
.whatsapp-button {
  margin: 0 40px;
}
@media only screen and (max-width: 756px) {
  .whats-app-content img {
    width: 70px;
  }
  .whatsapp-button {
    margin: 0 10px;
  }
}
@media only screen and (max-width: 400px) {
  .whats-app-content {
    flex-direction: column;
    justify-content: space-around;
  }
  .whats-app-content img {
    width: 120px;
  }
  .whatsapp-button {
    margin: 0;
  }
}
