.dropzone {
  position: relative;
  width: 100%;
  border-radius: 3px;
  background: #ffffff;
  border: 1px dashed #9b9b9b;
  cursor: pointer;
}
.dropzone span {
  font-family: HelveticaNeue;
  font-size: 18px;
  color: #9b9b9b;
  letter-spacing: 0;
  text-align: center;
}
.dropzone-big {
  height: 134px;
}
.dropzone-small {
  height: auto;
}
.dropzone-big svg.music-icon {
  height: 54px;
  width: 49px;
  margin: 12px;
}
.dropzone-small svg.music-icon {
  height: 29px;
  width: 27px;
  margin: 8px;
}
.rc-progress-line {
  height: 6px;
  width: 100%;
  position: absolute;
}
.dropzone svg {
  width: 44px;
  height: 44px;
  margin: 8px 0;
  color: rgb(7, 111, 159);
  fill: rgb(7, 111, 159);
}
.upload-icon {
  color: rgb(7, 111, 159);
  fill: rgb(7, 111, 159);
}
.user-upload-wrappers {
  margin: 20px 0 50px 0;
}

.button-show-document {
  margin-right: 20px
}

.buttons-drop-container {
  display: flex
}

.doc-succes-message {
  font-weight: 600
}

@media only screen and (max-width: 756px) {
  .buttons-drop-container {
    flex-direction: column
  }

  .button-show-document {
    margin-right: 0px;
    margin-bottom: 10px
  }
  .dropzone-big {
    height: auto;
    padding-bottom: 20px
  }
}