.loading-wrapper {
  position: absolute;
  left: 50%;
  top: 200px;
  background-color: white;
  z-index: 3;
}

.loading-small {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  display: flex;
  background-color: white;
}

.loading-icon {
  font-size: 40px;
  text-align: center;
  color: #adb5bd;
  margin-bottom: 20px;
}

#loading-logo {
  width: 40px;
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(359deg);
  }
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(359deg);
  }
}
