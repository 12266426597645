.cards-wrapper {
    display: flex;
    justify-content: space-evenly;
    padding-top: 20px;
  }

  .investor-list {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0;
    justify-content: space-around;
    padding: 0 1em !important;
  }

  .investor-list__item {
    list-style-type: disc !important;
    color: #808080;
  }

  .investor-list__item span {
    font-size: 0.7em !important;
  }

  
  .card {
    display: flex;
    -webkit-box-shadow: 0px 0px 24px -3px rgba(35, 193, 229, 0.4);
    -moz-box-shadow: 0px 0px 24px -3px rgba(35, 193, 229, 0.4);
    box-shadow: 0px 0px 24px -3px rgba(35, 193, 229, 0.4);
    width: 45vw;
    justify-content: space-evenly;
    padding: 20px;
  }
  
  @media (max-width: 1024px) {
    .cards-wrapper {
      flex-direction: column;
      align-items: center;
    }
  
    .card {
      margin-bottom: 20px;
      justify-content: center;
      width: 80vw;
    }
  }
  
  @media (max-width: 620px) {
    .card {
      flex-direction: column;
    }
  }
  
  .image-wrapper img {
    width: 250px;
    height: 250px;
  }
  
  .image-wrapper {
    display: flex;
    margin: 0 auto
  }
  
  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .text {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 20px;
  }
  
  .text p {
    font-size: 1.3rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
  }
  
  .text span {
    font-size: 0.8rem;
    color: gray;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .button-wrapper button {
    font-size: 0.7rem;
  }
  
  .button-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }
  
  @media (max-width: 420px) {
    .card {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 80vw;
      margin-bottom: 30px;
      padding: 30px;
    }
  }
  