.reset-row {
  height: 84vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset-token-succes {
  height: 300px;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column
}

.reset-token-succes p {
  color: #777;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  padding: 0px 20px
}

.reset-token-info {
  text-align: center;
  font-weight: 700;
  padding-bottom: 20px;
  margin: 0
}