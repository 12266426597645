.login-row {
  height: 84vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.signup-row {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px
}
.bad-credentials {
  padding: 4px 0 12px;
}
.login-section {
  width: 360px;
  margin: 10px auto;
}
.form-spaccing {
  padding: 16px;
}

.work-with-us-section {
  padding: 16px;
}

.reset-password-link {
  margin-top: 20px;
  font-size: 0.9rem;
  justify-content: space-between;
  display: flex
}

.reset-password-link p {
  margin: 0
}

.reset-password-link a {
  color: #888;
  transition: all 0.3s
}

.reset-password-link a:hover {
  color: #039be5;
  transition: all 0.3s
}

.signup-success {
  margin-top: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 20px
}

.signup-successful {
  background-color: #B4E2B5;
  width: 50vw;
  border-radius: 8px
}

.signup-successful p {
  text-align: center;
  color: #006904;
  font-size: 20px;
  text-transform: uppercase;
  margin: 30px 0px;
  font-weight: 500
}

.signup-success-info {
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center
}

.signup-success-info p,
.signup-success-info a {
  margin: 0;
  text-align: center;
}

.signup-success-info p {
  font-weight: 900
}

.signup-success-info span {
  color: #FF0100
}

.social-media-icons img {
  height: 30px;
  width: 30px
}

.social-media-icons a:not(:last-child) {
  margin-right: 10px
}

.social-centered {
  display: flex;
  justify-content: center;
}

.thank-you-company {
  margin-top: 80px
}

.thank-you-company p {
  font-size: 17px;
  text-align: center;
}

.thank-you-company p:last-of-type {
  font-weight: 700
}

.thank-you-company h2 {
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  line-height: 35px
}


@media screen and (min-width: 320px) and (max-width: 465px) {
  .signup-successful {
    background-color: #B4E2B5;
    width: 100%;
    border-radius: 8px
  }

  .signup-success-info p {
    margin-bottom: 20px
  }
}

[type="radio"]:not(:checked), [type="radio"]:checked {
  position: relative;
  opacity: 1;
  pointer-events: auto
}