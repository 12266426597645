.container-home-banner {
  display: flex;
  justify-content: center;
  color: white;
}

.container-background {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  height: 500px;
  background-size: cover;
  width: 100vw;
  height: calc(100vh - 87px);
  background-repeat: no-repeat;
  background-position: center;
}

.image-container-text {
  display: flex;
  width: 80vw;
  flex-direction: column;
}

.container-home-banner h1 {
  margin-bottom: 0px;
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 3rem;
}

.container-home-banner h2 {
  font-size: 1.1rem;
  margin-top: 20px
}

.buttons-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.buttons-wrapper button {
  margin-top: 40px;
  margin-right: 40px
}

@media (max-width: 767px) {
  .container-home-banner h1 {
    font-size: 1.4rem;
    line-height: 1.6rem
  }

  .container-home-banner h2 {
    font-size: 1rem;
    line-height: 1.4rem;
  }

  .buttons-wrapper {
    flex-direction: column;
  }
  .buttons-wrapper button {
    margin: 20px 0px;
  }
}

@media (max-width: 992px) {
  .container-background {
    width: 100%;
  }
}
