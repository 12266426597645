.home-features {
  min-height: 100vh;
  padding: 20px 0;
}
.home-features > div {
  padding-top: 45px;
}
.home-features .feature-text {
  line-height: 1.8;
  margin-bottom: 40px;
}
.feature-details > div {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 20px;
  flex-direction: column;
}
