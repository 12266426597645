.video-section-container {
	display: flex;
	height: 50vh;
	background-image: linear-gradient(to right, #00a1c9, #0071a3);
}

.video-section-button-container {
	display: flex;
}

.video-section-button-container button:first-child {
	margin: 0 0.2em;
}

@media (max-width: 769px) {
	.video-section-button-container {
		flex-direction: column;
		align-items: center;
	}
	.video-section-button-container button:first-child {
		margin-bottom: 1em;
	}
}

.video-section-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1;
	color: white;
}

.video-section-title {
	font-size: 1.9rem;
	margin-bottom: 0;
}

.video-section-body {
	font-size: 1rem;
	line-height: 1.4rem;
}

.video-section-player {
	display: flex;
	flex: 1;
	outline: 0;
}

.video-section-text-wrapper {
	display: flex;
	align-items: center;
	width: 80%;
	flex-direction: column;
}

iframe {
	border: none;
}
@media (max-width: 767px) {
	.video-section-container {
		flex-direction: column;
		height: auto;
	}
	.video-section-content {
		width: 100%;
		height: auto;
		align-items: center;
		justify-content: center;
	}

	.video-section-text-wrapper {
		justify-content: center;
		align-items: center;
		text-align: center;
	}
	.video-section-player {
		width: 100%;
		height: 50vh;
	}
	.video-section-title {
		font-size: 1.5rem;
	}
	.video-section-body {
		font-size: 0.8rem;
	}
}

@media (max-width: 1023px) {
	.video-section-text-wrapper {
		margin-bottom: 20px;
	}
}
