.my-investments {
  .accordion-investments {
    margin-bottom: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  .title-investments {
    font-size: 1.2rem;
    padding-bottom: 14px;
    border-bottom: 2px solid #3dc6ef;
    margin-top: 20px;
    text-align: center;
    font-weight: 600;
    display: flex;
    justify-content: center;
    @media (max-width: 415px) {
      flex-direction: column;
    }
    p {
      margin: 0 1em;
      @media (max-width: 414px) {
        margin: 0.4em 0;
      }
    }
    &:not(:first-of-type) {
      margin-top: 60px;
    }
  }
  p {
    margin: 0px;
    display: block;
  }

  @media (max-width: 769px) {
    .my-investments .title-investments {
      flex-direction: column;
      font-size: 1rem;
    }
  }
  .investments-container {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    min-height: 150px;
    position: relative;
    .investments-row {
      width: 100%;
      display: flex;
      flex: 1;
      .investments-content {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 14px;
        &.--img {
          background-color: white;
          min-height: 150px;
          img {
            align-self: center;
            width: 213px;
            height: 128px;
          }
        }
        .investments-title {
          font-weight: 600;
          margin-bottom: 10px;
          text-align: center;
        }
      }
    }
    .toggle-modal-btn {
      bottom: 15px;
      right: 15px;
      position: absolute;
      background-color: #076f9f;
      height: 30px;
      width: 30px;
      border-radius: 15px;
      color: white;
      border: none;
      cursor: pointer;
    }
    @media screen and (max-width: 575px) {
      flex-direction: column;
      flex: 1;
      .investments-row {
        flex: 1;
        min-height: 100px;
        flex-direction: column;
        .investments-content {
          &.--img {
            min-height: auto;
          }
        }
      }
    }
  }

  .investment-actions {
    display: flex;
    padding-bottom: 20px;
    justify-content: center;
    div {
      button {
        font-size: 12px;
        padding: 12px;
      }
      &:first-child {
        margin-right: 20px;
      }
    }
    @media screen and (max-width: 575px) {
      flex-direction: column;
      align-items: center;
      div {
        &:first-child {
          margin-right: 0px;
          margin-bottom: 10px;
        }
      }
    }
  }

  .accordion-investments {
    border-top: 1px solid rgba(0, 0, 0, 0.16);
    tbody {
      .accordion-table-row {
        cursor: pointer;
        background-color: white;
        transition: all 0.3s;
        &:hover {
          background-color: rgba(0, 0, 0, 0.075);
          transition: all 0.3s;
        }
      }
    }
    .accordion-table-row {
      display: flex;
      .accordion-table-cell {
        font-weight: normal;
        flex: 1;
        text-align: center;
        display: flex;
        justify-content: center;
        p {
          padding: 0 0.5em;
          margin-right: 1.6em;
        }
        &.--header {
          font-weight: 600;
        }
      }
    }
  }

  .pledges-container {
    position: relative;
    display: flex;
    background-color: #f0f0f0;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

    .pledges-row {
      display: flex;
      height: 100%;
      width: 50%;
      min-height: 150px;
      flex: 2;
      @media (max-width: 1025px) {
        align-items: center;
      }
      @media (max-width: 415px) {
        flex-direction: column;
      }
    }

    .pledges-content {
      padding: 14px;
      flex: 1;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      min-height: 150px;

      @media (max-width: 769px) {
        min-height: initial;
      }

      &:first-of-type {
        background-color: white;
        img {
          align-self: center;
          width: 213px;
          height: 128px;
        }
      }

      .pledges-title {
        font-weight: 600;
        width: 200px;
        margin-bottom: 10px;
        text-align: center;
        @media (max-width: 769px) {
          width: initial;
        }
      }
    }
    .pledges-content-bigger {
      padding: 14px;
      width: 50%;

      @media (max-width: 1025px) {
        width: 30%;
      }
      display: flex;
      justify-content: flex-start;
      align-items: center;
      min-height: 150px;
      .pledges-steps-container {
        display: flex;
        justify-content: center;
        .pledges-steps {
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          i {
            font-size: 16px;
            margin-right: 5px;
          }
        }
        .icon-close {
          cursor: pointer;
          position: absolute;
          top: 8px;
          right: 8px;
          font-size: 20px;
          font-weight: 600;
        }
      }
    }
    @media screen and (max-width: 575px) {
      flex-direction: column;
      .pledges-row,
      .pledges-content-bigger {
        width: 100%;
        min-height: initial;
      }
    }
  }
}

.col-mw50 {
  min-width: 50px;
}

.col-mw80 {
  min-width: 80px;
}

.col-mw100 {
  min-width: 100px;
}

.Modal {
  position: absolute;
  top: 30px;
  left: 30px;
  right: 30px;
  bottom: 30px;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  @media (max-width: 575px) {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border-radius: 0px;
    overflow-y: scroll;
  }
  p {
    margin: 0;
  }
  .modal-header {
    display: flex;
    justify-content: space-between;
    h4 {
      margin-top: 0px;
      margin-bottom: 30px;
      font-size: 18px;
      @media (max-width: 575px) {
        font-size: 1em;
        margin-bottom: 15px;
      }
    }
    i {
      margin-top: 5px;
      cursor: pointer;
    }
  }
  .table-modal {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 8px;
    .modal-row {
      display: flex;
      padding: 0.75rem 1.25rem;
      @media (max-width: 575px) {
        padding: 0.4rem 1rem;
      }
      p {
        flex: 1;
        text-align: center;
      }
      @media (max-width: 575px) {
        p {
          font-size: 0.83em;
        }
      }
      &:not(:last-of-type) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      }
    }
  }
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
}

.modal-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal-container-anticipation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
}

.modal-footer {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2em;
  cursor: pointer;
  p {
    padding: 1em;
  }
}

.green-text {
  color: green !important;
}

.button {
  padding: 0.7em 1em;
  border: none;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  margin: 0 0.8em;
  &:focus {
    box-shadow: none;
    background-color: white !important;
  }
}

.button--red {
  color: #fff;
  background: #a80303;
  transition: all 0.3s;
  &:hover {
    background-color: #880202 !important;
  }
  &:focus {
    background-color: #880202 !important;
  }
}

.button--bordered {
  border: 1px solid #555;
  color: #555;
  transition: all 0.3s;
  &:hover {
    background: #555 !important;
    color: white;
    border: 1px solid white;
  }
}

@media (max-width: 769px) {
  .button {
    width: 80%;
    margin: 1em 0;
  }
}

.investment-summary {
  display: flex;
  width: 100%;
  padding: 1.5em 0;
  font-weight: bold;
  justify-content: center;
}

.investment-summary p {
  margin: 0 0.2em;
}

@media (max-width: 769px) {
  .investment-summary {
    flex-direction: column;
    align-items: center;
  }

  .investment-summary p {
    margin: 0.2em 0;
  }
  .investment-summary *:not(:first-child):not(:last-child) {
    display: none;
  }
}

.campaign-image-container {
  @media (max-width: 415px) {
    flex-direction: column;
  }
}
