.input-field {
  margin-top: 1.6rem;
  width: 100%;
}
.input-field .helper-text {
  color: red;
  font-size: 12px;
}

.select-group .helper-text {
  color: red;
  font-size: 12px;
}
form .helper-text {
  color: red;
  font-size: 12px;
}

.read-only-text {
  color: #a6a6a6
}

.signup-textarea {
  height: 150px;
}

.form-signup-company {
  margin-top: 40px;
  margin-bottom: 80px
}