.how-it-work {
  padding: 80px 0px
}

.icons-container {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}

.icons-content {
  display: flex;
  width: 80vw;
  text-align: center;
  justify-content: space-between;
}

.icon-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-container svg {
  width: 120px;
  height: auto
}
  
.icon-container p {
  font-size: 0.8rem;
  color: rgb(153, 153, 153);
  font-weight: 600;
}
  
.arrow-wrapper svg {
  width: 80px;
  height: auto;
}
  
.icons-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
  
.arrow-wrapper {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
}
.section-description {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.section-description h3 {
  margin-bottom: 20px;
  color: #2a4466;
  font-size: 2rem;
}

.section-description p {
  color: rgb(153, 153, 153);
  font-weight: 500;
  margin: 0px
}

.text-wrapper {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  text-align: center;
}

  
@media (max-width: 768px) {
  .icons-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }
  .arrow-wrapper {
    display: flex;
    transform: rotate(90deg);
    padding: 0px;
  }

  .arrow {
    width: 60px;
  }

  .icons-content {
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    flex-direction: column;
    width: 200px
  }

  .text-wrapper {
    width: 60%;
  }

  .sub-description span{
    display: block
  }

}
  