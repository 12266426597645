.contact-form {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-section {
  width: 360px;
  margin: 10px auto;
}

.success-contact-form {
  margin-top: 20px;
  border: 2px solid #398f14;
  padding: 10px 20px;
  max-width: 360px;
}

.success-contact-message {
  margin: 0px;
  text-align: center;
  font-weight: 500;
}

.work-with-us-form {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.work-with-us-form .work-with-us-form__input {
  margin: 0 !important;
}

.input-error {
  font-size: 0.8em;
  color: red;
  break-after: always;
}

.success-work-with-us-form {
  max-width: 800px;
  padding: 0px 20px;
}

.success-work-with-us-form p {
  text-align: center;
}

.success-work-with-us-message {
  font-size: 24px;
  color: rgb(123, 119, 113);
  font-weight: 600;
}

.success-work-with-us-form p span {
  display: block;
}

@media only screen and (max-width: 400px) {
  .contact-form {
    margin: 0px 20px;
  }
  .contact-section {
    width: 100%;
  }
}

.visibility-hidden {
  visibility: hidden;
}

.file-input {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 1e-5;
  pointer-events: none;
}

.file-input-wrapper {
  position: relative;
  padding: 20px;
  margin: 5px 0px 30px;
}



.checkbox input[type='checkbox'] {
  opacity: 0;
}

.checkbox label {
  position: relative;
  display: inline-block;

  /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
  padding-left: 22px;
}

.checkbox label::before,
.checkbox label::after {
  position: absolute;
  content: '';

  /*Needed for the line-height to take effect*/
  display: inline-block;
}

/*Outer box of the fake checkbox*/
.checkbox label::before {
  height: 16px;
  width: 16px;

  border: 1px solid;
  left: 0px;

  /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
   *to vertically center it.
   */
  top: 3px;
}

/*Checkmark of the fake checkbox*/
.checkbox label::after {
  height: 5px;
  width: 9px;
  border-left: 2px solid;
  border-bottom: 2px solid;

  transform: rotate(-45deg);

  left: 4px;
  top: 7px;
}

/*Hide the checkmark by default*/
.checkbox input[type='checkbox'] + label::after {
  content: none;
}

/*Unhide on the checked state*/
.checkbox input[type='checkbox']:checked + label::after {
  content: '';
  color: #288aa8;
}

.input-field__checkbox:hover {
  cursor: pointer;
}

.checkbox-row {
  display: flex;
  /* justify-content: space-evenly; */
  margin-bottom: 1em;
  flex-wrap: wrap;
}

.checkbox-row__title {
  text-align: center;
  font-size: 1.2em;
  color: #9e9e9e;
  margin: 0;
  margin-bottom: 1em;
}

.checkbox {
  display: flex;
  /* flex: 1; */
  width: 50%;
  white-space: nowrap;
  justify-content: flex-start;
}

.checkbox-container {
  margin-bottom: 2em;
  height: 150px;
}

.user-uploads-wrapper {
  margin-bottom: 2em;
}
