.section-description {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    padding-top: 50px;
  }
  
  .section-description h3 {
    margin-bottom: 20px;
    color: #2a4466;
    font-size: 2rem;
  }
  
  .section-description p {
    color: rgb(153, 153, 153);
    font-weight: 500;
  }

  .bold {
    font-weight: bold;
  }
  
  .text-wrapper {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    text-align: center;
  }
  
  @media (max-width: 769px) {
    .text-wrapper {
      width: 80%;
    }
  }

  .questionCardContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
  }
  
  .questionCard {
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    width: 30%;
  }
  
  .questionTitle {
    font-size: 1.8rem;
    margin-bottom: 0px;
    color: #2a4466;
  }
  
  .questionBody {
    color: rgb(153, 153, 153);
  }

  .questionBody__link {
    color: rgb(153, 153, 153);
  }
  
  @media (max-width: 768px) {
    .questionCardContainer {
      flex-direction: column;
    }
  
    .questionCard {
      width: 80%;
      text-align: left;
    }
  
    .questionCardContainer {
      align-items: center;
    }
  }
  
  