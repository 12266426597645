.has-tip {
    cursor: help;
    display: inline-block;
    position: relative;
    -webkit-transform: translateZ(0);
  }
  
  .tooltip {
    bottom: 100%;
    color: #fff;
    display: block;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 8px;
    left: 0;
    right: 0;
    margin: auto;
    margin-bottom: 15px;
    opacity: 0;
    padding: 10px;
    pointer-events: none;
    position: absolute;
    -webkit-transform: translateY(10px);
    -ms-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-transition: all .25s ease-out;
    transition: all .25s ease-out;
  }
  
  .tooltip::after {
    border: transparent solid 10px;
    border-top-color: rgba(0, 0, 0, 0.8);
    content: '';
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    top: 100%;
    width: 0;
  }
  
  /* This bridges the gap so you can mouse into the tooltip without it disappearing */
  .has-tip .tooltip:before {
    bottom: -20px;
    content: '';
    display: block;
    height: 20px;
    left: 0;
    position: absolute;
    width: 100%;
  }
  
  .has-tip:hover .tooltip,
  .validate:focus + .tooltip {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }
  
  /* IE can just show/hide with no transition */
  .lte8 .has-tip .tooltip {
    display: none;
  }
  
  .lte8 .has-tip:hover .tooltip {
    display: block;
  }
  