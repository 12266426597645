@import url('components/admin.css');
@import url('components/header.css');
@import url('components/footer.css');
@import url('components/homebanner.css');
@import url('components/homefeatures.css');
@import url('components/flash-message.css');
@import url('components/home-buttons-section.css');
@import url('components/button.css');
@import url('components/team.css');
@import url('components/whats-app-business.css');
@import url('components/campaign-gallery.css');
@import url('components/campaign-page.css');
@import url('components/login.css');
@import url('components/dropzone.css');
@import url('components/loading-screen.css');
@import url('components/input.css');
@import url('components/user-account-options-page.css');
@import url('components/campaign-card.css');
@import url('components/password-reset.css');
@import url('components/testimonials.css');
@import url('components/contact.css');
@import url('components/icons-home.css');
@import url('components/graphics.css');
@import url('components/wearekavod.css');
@import url('components/homequestions.css');
@import url('components/homelaptop.css');
@import url('components/investor.css');
@import url('components/personal-form.css');
@import url('components/tooltip.css');
@import url('components/home-news.css');
@import url('components/overwrite-materialize.css');
@import url('components/my-investments.css');

html {
  scroll-behavior: smooth;
}
body {
  padding: 80px 0 0 0;
  font-family: sans-serif;
}
#home {
  color: #2a4466;
}
#root {
  position: relative;
}
.stick-component {
  min-height: 84vh;
}
.dropdown-content.nested {
  overflow-y: visible;
}
.error-msg {
  font-weight: 900;
}
.text-white {
  color: #fff;
}
.text-dark {
  color: #404040;
}
.text-gray {
  color: #939393;
}
.bg-gray {
  background-color: #272727;
}
.bg-black {
  background-color: #171717;
}
.strong-text {
  font-weight: 900;
}
.no-margin {
  margin: 0;
}
.dfp-bottom {
  padding-bottom: 12px;
}
.dfp-bottom-big {
  padding-bottom: 24px;
}
.dfp {
  padding: 12px;
}
.dfm {
  margin: 0.5rem 0 1rem 0;
}
.flex-center-column {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.flex-center-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.flex-center-flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
}
.sidenav li > a {
  font-size: 18px;
}
a:focus {
  outline: 0;
}
.dropdown-content {
  min-width: 200px;
}
.dropdown-content li > a,
.dropdown-content li > span {
  color: #404040;
}
form {
  padding: 8px;
}
.btn-block,
.full-width {
  width: 100%;
}
.datepicker-full-width > div:nth-child(2),
.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}
.react-datepicker-popper {
  z-index: 3 !important;
}
@media only screen and (max-width: 600px) {
  div.styled-cep {
    margin-top: 25px;
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 400px) {
  .flex-center-column-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
@media only screen and (max-width: 360px) {
  .login-section {
    width: 340px;
  }
}
@media only screen and (max-width: 340px) {
  .login-section {
    width: 320px;
  }
}
@media only screen and (max-width: 320px) {
  .login-section {
    width: 320px;
    margin: 0;
  }
}
.values {
  color: #686c8b;
  font-weight: 700;
}
.values-label {
  color: #686c8b;
}

.react-quill .ql-size-large {
  text-transform: none ;
  font-weight: bold ;
  color: #076f9f ;
  font-size: 1.2em;
}

.react-quill .ql-size-huge {
  color: #fff !important ;
  background: #076f9f;
  display: flex;
  width: 100%;
  padding: 0.3em 0;
  justify-content: center;
}

.react-quill strong {
  font-weight: bold ;
  text-transform: none ;
  color: #000 ;
  font-size: 1em ;
}

.react-quill .ql-syntax {
  background-color: #23241f;
  color: #f8f8f2;
  padding: 0 0.3em;
}

.react-quill span {
  color: #000 ;
  font-size: 1em ;
  font-weight: normal ;
  text-transform: none ;
}

.react-quill img {
  max-width: 100%;
}

.blue-kavod {
	color: #076f9f !important;
}

.background-blue-kavod {
	background-color:#076f9f !important;
}

.switch label input[type=checkbox]:checked+.lever {
	background-color: #9ec6d8;
}

.switch label input[type=checkbox]:checked+.lever::after {
	background-color: #076f9f;
}
