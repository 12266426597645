.news-container {
  padding: 20px 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
}

.news-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 3em;
  opacity: 1;
  transition: all 0.3s;
}

@media(max-width: 992px) {
  .news-content {
    margin: 0 1em;
  }
}

@media(max-width: 620px) {
  .news-content {
    margin: 0 0.5em;
  }
}

.fade {
  opacity: 0;
  transition: all 1s;
}

@media (max-width: 769px) {
  .news-container {
    padding: 20px 0px;
  }
}

@media (max-width: 500px) {
  .news-container {
    padding: 20px 0px;
    width: 100%;
  }
}

.news-img {
  height: 120px;
  display: flex;
  align-items: center;
}


.hidden {
  visibility: hidden;
  width: 0;
  margin: 0;
  opacity: 0;
}

.news-content img {
  width: 100px;
  height: auto;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  transition: all 0.3s;
  margin: auto;
}

@media(max-width: 769px) { 
  .news-img img {
    width: 45px;
  }
}

.news-content:hover {
  cursor: pointer;
}

.news-row {
  display: flex;
  margin-top: 40px;
}

.news-row .news-divider:not(:last-of-type) {
  margin-right: 50px;
}

.news-row .news-content:not(:last-of-type) {
  margin-right: 50px;
}

.news-content:hover img {
  -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
  filter: grayscale(0%);
  transition: all 0.3s;
}

.news-content p {
  text-align: center;
  transition: all 0.3s;
}

.news-content:hover p {
  color: #2a4466;
  transition: all 0.3s;
}

.news-divider {
  display: flex;
  flex: 1;
}

@media (max-width: 768px) {
  .news-divider {
    flex-direction: column;
  }

  .news-row {
    margin-top: 0px;
  }
}

@media (max-width: 575px) {
  .news-row {
    flex-direction: column;
  }

  .news-divider {
    margin: 0px !important;
  }
}

.next-news {
  width: 3em;
  cursor: pointer;
}

.previous-news {
  width: 3em;
  cursor: pointer;
  transform: rotate(180deg);
}

@media (max-width: 769px) {
  .next-news {
    width: 1.5em;
  }
  .previous-news {
    width: 1.5em;
  }
}
