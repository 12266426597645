.flash-message {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  height: 86px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dff0d8;
  border-color: #d0e9c6;
  color: #3c763d;
  position: fixed;
  top: 0;
  z-index: 999999999999;
  width: 100%;
}
.flash-message p {
  margin: 0;
  text-align: center;
}
.flash-message span {
  display: flex;
  align-items: center;
}
.flash-message svg {
  position: absolute;
  right: 10px;
  cursor: pointer;
}
