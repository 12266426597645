.ReactModal__Overlay {
  display: flex;
  justify-content: center;
  align-items: center;

  .Modal-ChangePassword {
    position: absolute;
    width: 700px;
    height: 520px;
    background-color: white;
    border-radius: 8px;
    @media (max-width: 670px) {
      top: 0;
      left: 0;
    }
    p {
      margin: 0;
      span {
        color: #076f9f;
        font-weight: 600;
        &.bold-span {
          color: rgba(0, 0, 0, 0.87);
        }
      }
      &.spaced-text {
        margin-bottom: 14px;
      }
      a {
        text-decoration: underline;
      }
      button {
        color: #039be5;
        cursor: pointer;
        border: none;
        padding: 0px;
        margin: 0px;
        text-decoration: underline;
      }
    }
    .modal-password-container {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      .close-modal-button {
        position: absolute;
        top: 14px;
        right: 20px;
        font-size: 20px;
        color: white;
        cursor: pointer;
      }
      .modal-password-title {
        color: white;
        background-color: #076f9f;
        padding: 14px 0px;
        text-align: center;
        margin: 0;
        font-weight: 600;
        font-size: 28px;
        line-height: 25px;
      }
      .modal-password-sub-title {
        color: #076f9f;
        padding: 10px 0px;
        text-align: center;
        margin: 0;
        font-weight: 600;
        font-size: 30px;
      }
      .rocket-steps-container {
        display: flex;
        flex: 1;
        img {
          align-self: flex-end;
          margin-right: 10px;
        }
      }
      .crowd-image {
        position: absolute;
        bottom: 0;
        width: 100%;
      }
      .rocket-image {
        z-index: 2;
      }
      .reset-instructions {
        padding: 0px 30px;
        font-size: 16px;
        @media (max-width: 670px) {
          padding: 0px 20px;
        }
      }
      .steps-container {
        padding: 20px 20px 0px;
        p {
          font-size: 16px;
        }
      }
    }
    @media (max-width: 670px) {
      overflow-y: scroll;
      height: auto;
      width: auto;
      margin: 20px;

      .rocket-steps-container {
        flex-direction: column-reverse !important;
        img {
          align-self: flex-start !important;
          margin-top: 20px;
        }
      }
    }
  }
  @media (max-width: 670px) {
    overflow-y: scroll;
    height: auto;
    width: auto;

    .modal-password-container {
      .rocket-steps-container {
        flex-direction: column-reverse;
        img {
          align-self: flex-start;
          margin-top: 20px;
        }
      }
    }
  }
}
