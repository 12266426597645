.user-account-options-wrapper .col {
  padding-top: 50px;
}
.user-account-options-wrapper img {
  max-width: 150px;
}
.user-account-page .material-icons {
  fill: #266f9f;
  color: #266f9f;
}
