.team-section {
  padding-top: 40px;
  padding-bottom: 66px;
}
.team-section subtitle {
  color: #000;
  font-size: 16px;
}
.team-section .profile-image {
  overflow: hidden;
  width: 174px;
  height: 174px;
  margin: auto;
  margin-bottom: 25px;
  border-radius: 50%;
}
.members {
  padding: 30px 0 40px;
}
.member-icon svg {
  width: 20px;
  height: 20px;
  color: #808080;
}
.member-icon svg:hover {
  color: #3dc6ef;
  transition: 0.3s;
}
.card-member {
  position: relative;
  height: 320px;
  margin-top: 15px;
}
.member-name {
  font-size: 17px;
  color: #2a4466
}
.member-role {
  font-size: 13px;
}

.member-description {
  display: initial;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 0;
  height: 200px;
  width: 255px;
  color: white;
  background: #333;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: justify;
  font-size: 13px;
  opacity: 1;
  transition: opacity 0.4s, z-index 10s
}

hr {
  height: 2px;
  width: 100px;
  background: green;
}

.invisible-description {
  opacity: 0;
  transition: opacity 0.4s, z-index 10s
}
