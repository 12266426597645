.toastify__login {
    font-size: 1.1em;
    padding: 1em;
}

.toastify {
    margin-top: 110px;
    padding: 1em;
    color: #2a4466;
}
  
.toastify__login--progress {
background: #076f9f;
}