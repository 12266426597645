.profiles-container {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 50px;
  }
  
  @media (max-width: 768px) {
    .profiles-container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 15px;
    }
  }
  
  .profile-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  
    flex: 0.2;
  }
  
  .profile-image {
    width: 200px;
    border-radius: 50%;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  }
  
  .profile-body {
    font-size: 0.8rem;
    color: rgb(153, 153, 153);
  }
  
  .name-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  @media (max-width: 768px) {
    .profile-wrapper {
      flex: 0.8;
      margin-bottom: 30px;
    }
  
    .profile-wrapper {
      flex-direction: row;
    }
  }
  
  .profile-content {
    display: flex;
    flex-direction: column;
    padding: 15px;
  }
  
  @media (max-width: 520px) {
    .profile-wrapper {
      flex-direction: column;
    }
  }
  