.home-buttons-section {
  background-color: rgba(196, 196, 196, 0.96);
  min-height: 70vh;
}
.home-buttons-section > div:first-child {
  padding: 100px 0 40px;
}
.action-buttons span {
  font-size: 18px;
}
.company-button {
  background: url(/assets/img/home/company-button.png) no-repeat center;
}
.investor-button {
  background: url(/assets/img/home/investor-button.png) no-repeat center;
}
.button-section {
  padding: 30px;
}
.button-section span {
  padding: 20px 0 12px;
}
.button-section span.company {
  border-bottom: 2px solid #076f9f;
}
.button-section span.investor {
  border-bottom: 2px solid #3dc6ef;
}
.rounded-button {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 50%;
}
.rounded-link {
  border: 10px solid #ececec;
  width: 145px;
  height: 145px;
  border-radius: 50%;
}
.buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
@media only screen and (max-width: 400px) {
  .buttons-container {
    flex-direction: column;
  }
}
