@media (max-width: 620px) {
  body {
    cursor: default;
  }
}

.header {
  display: flex;
  width: 100vw;
  flex-direction: row;
  justify-content: flex-start;
  height: 80px;
  position: fixed;
  top: 0;
  background: transparent;
  z-index: 999;
  background-color: white;
}

@media (max-width: 620px) {
  body {
    cursor: default;
  }
}

.logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.display-menu {
  display: flex !important;
}
.navbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  transition: all 0.4s ease-in-out;
}

.navbar-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 0px 50px;
}

.nav-list {
  display: flex;
  list-style: none;
  align-items: flex-start;
  padding: 0;
}

.nav-item a {
  color: #2a4466;
}

.nav-item p {
  margin: 0;
}

.nav-item {
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;
  padding: 8px;
}

.nav-item-bordered:hover {
  color: #1aa0be;
}

.nav-item-bordered {
  text-decoration: none;
}

.nav-item a:hover {
  transition: all 0.2s;
}

.nav-hamburger {
  font-size: 30px;
  display: none;
  padding: 20px 20px;
  color: #2a4466;
  cursor: default;
}

.isOpen {
  max-height: 1000px !important;
}

@media (max-width: 1024px) {
  .nav-hamburger {
    display: block;
    height: auto;
  }
  .header {
    height: auto;
    padding: 0px 25px;
    background: white;
    align-items: flex-start;
  }
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    max-height: 0;
    overflow: hidden;
  }

  .nav-item {
    color: #2a4466;
  }

  .navbar-wrapper {
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 0px;
  }

  .nav-list {
    flex-direction: column;
  }

  .icon-padding {
    padding: 5px;
  }

  .logo-wrapper {
    margin-top: 15px;
  }
}

.dropdown {
  display: none;
  position: absolute;
  padding: 10px 20px;
  -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.36);
  background-color: white;
}

.nav-item:hover .dropdown {
  display: flex;
  flex-direction: column;
}

.sub-item {
  padding: 5px;
}
.nav-icon {
  padding-left: 5px;
}
.dropdown-content2 {
  margin: 10px 20px;
}

.dropdown:hover .dropdown-content2 {
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .dropdown {
    padding: 5px;
    position: static;
    box-shadow: none;
    display: flex;
    flex-direction: column;
  }

  .dropdown-content2 {
    margin: 0px;
    padding: 5px 10px;
  }
  .sub-item {
    padding: 5px;
  }
}

@media (max-width: 768px) {
  .icon {
    padding: 0 1em;
    margin: 0 1em;
    font-size: 1.2em;
    z-index: 4;
    position: relative;
    left: 40px;
  }
}

.icon {
  cursor: pointer;
}
