.graphics-section {
    display: flex;
    padding: 50px 0px;
    justify-content: center;
    align-items: center;
  }
  
  .blue-graphic {
    background-image: linear-gradient(to right, #00a1c9, #0071a3);
    color: white;
  }
  
  .graphics-section-content {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 30px;
    padding: 0 50px;
    flex: 1;
  }
  
  .graphics-section-content h2 {
    margin-bottom: 10px;
    font-size: 2rem;
  }
  
  .graphics-section-content p {
    font-size: 0.9rem;
  }
  
  .content-wrapper {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .content-wrapper button {
    margin-top: 20px;
  }
  
  .content-wrapper p {
    line-height: 1.2rem;
    margin: 10px 0px;
  }
  
  .image {
    width: 400px;
    height: 400px;
  }
  
  .button-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  
  @media (max-width: 992px) {
    .button-container {
      justify-content: center;
    }
    .graphics-section {
      flex-direction: column;
      height: 100%;
    }
    .graphics-section-content {
      padding: 0 10px;
      align-items: flex-start;
      justify-content: center;
    }
  
    .icon-container {
      width: 90vw;
    }
  
    .content-wrapper {
      justify-content: center;
      align-items: flex-start;
      text-align: left;
    }
  
    .image {
      width: 65vw;
      height: 100%;
    }
  }
  
  .graphic {
    display: flex;
    flex: 1;
  }